<template>
    <div class="">
        <v-card :loading="loading" :disabled="loading" loader-height="2" class="all-width screen-height shadow-off">
            <v-card-text class="screen-height">
                <div class="d-flex align-center ml-2 justify-space-between">
                    <div class="d-flex">
                        <h1 class="d-flex align-center ma-0 text-h5 black--text font-weight-black" v-if="!editHeader">{{this.$store.state.LocationHeader|| 'Location'}}</h1>
                        <lb-string class="" v-if="editHeader" :isClearable="false" v-model="newHeader" label="" />

                        <v-tooltip bottom content-class="tooltip-bottom" v-if="editHeader">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" class="shadow-off ml-1" icon @click="UpdateHeader">
                                    <v-icon size="24">mdi-check</v-icon>
                                </v-btn>
                            </template>
                            <span>Submit</span>
                        </v-tooltip>
                        <v-tooltip bottom content-class="tooltip-bottom" v-else>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" text @click="editHeader=true; newHeader=$store.state.LocationHeader " class="shadow-off ml-1" icon>
                                    <v-icon size="24">mdi-pencil-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>Edit</span>
                        </v-tooltip>

                        <v-tooltip bottom content-class="tooltip-bottom">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" class="shadow-off ml-1" icon @click="refreshData()">
                                    <v-icon size="24">mdi-refresh</v-icon>
                                </v-btn>
                            </template>
                            <span>Refresh</span>
                        </v-tooltip>
                    </div>
                    <div class="d-flex align-center justify-center">
                        <v-btn small color="" class="mr-1 border-dark px-4 py-1 " height="4vh">
                            <v-icon class="pr-2">mdi-file-document-plus-outline</v-icon>
                            <p class="ma-0 pa-0">Update From ERP</p>
                        </v-btn>
                        <v-btn small height="4vh" color="black" class="mr-1 px-4 border-dark white--text" v-if="$nova.hasRight(right, 'edit') || $nova.hasRight(right, 'add')" @click="locationdialog = true;locationdata = {};locationdataerror= {};">
                            <v-icon class="mr-1">mdi-plus</v-icon>
                            <span>Add {{$store.state.LocationHeader || 'Location'}}</span>
                        </v-btn>
                    </div>
                </div>

                <div class="d-flex align-center ml-2 justify-space-between my-3 mr-1">

                    <lb-string placeholder="Search" label="" v-model="search" width="30vh" appendiconinner="mdi-magnify" hidedetails :isClearable="false" radius="8px" />
                    <v-tooltip bottom content-class="tooltip-bottom">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn class="ma-2" text icon color="lighten-2" v-bind="attrs" v-on="on">
                            <v-icon>mdi-information</v-icon>
                            </v-btn>
                        </template>
                        <span>Search for the description </span>
                        </v-tooltip>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-menu :close-on-content-click="false" offset-y transition="slide-y-transition">
                        <template v-slot:activator="{ on, attrs }">
                            <v-tooltip left content-class="tooltip-left">
                                <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                                    <v-btn icon v-bind="{ ...attrs, ...tooltipAttrs }" v-on="{ ...on, ...tooltipOn }" class="ml-2 border-on">
                                        <v-icon color="caccounting">mdi-table-large-plus</v-icon>
                                    </v-btn>
                                </template>
                                <span>Customize column</span>
                            </v-tooltip>
                        </template>

                        <v-list style="max-height:50vh ; overflow:hidden; z-index:13 !important" class="pa-0 ma-0 d-flex flex-column align-start justify-start all-width">
                            <v-list-title class="ma-0  all-width">
                                <div class="d-flex grayBackground align-center pa-2 pb-3 pt-3 pb-1 justify-space-between">
                                    <p class="ma-0 font-weight-bold">Customize Column</p>
                                </div>
                                <v-divider></v-divider>
                                <lb-string label="" placeholder="Search" class="px-2 mt-3 mb-2" v-model="searchcolumn" width="30vh" :clearable="false" appendiconinner="mdi-magnify" hidedetails radius="8px" />
                            </v-list-title>


                            <v-list-item style="max-height:25vh ; overflow:scroll" class="d-flex all-width align-start pa-0 ma-0 flex-column">
                                <v-list-item v-for="(item, index) in filteredHeaders" class="d-flex pa-0 px-2 ma-0 " :key="index">
                                    <v-checkbox color="caccounting" v-if="item.text !== 'Action'" :ripple="false" class="pa-0 ma-0" @change="addTableHeader(item)" hide-details :input-value="item.visible" :label="item.text"></v-checkbox>
                                </v-list-item>

                            </v-list-item>

                        </v-list>
                    </v-menu>
                </div>

                <lb-datatableaction :headers="headers" :tabledata="formatedDataCopy" :itemsperpage="itemsPerPage" :searchterm="search" :bulkActions="false" @filter-item="filterPart" @clear-item="clearPart">
                    <template v-slot:tbody>
                        <tr v-for="item in paginatedFilteredItems" :key="item._id" class="cursor-pointer  " :class="{ 'low-opacity': item.status === false }">
                            <template v-for="header in headers">
                                <td v-if="header.visible && header.value !== 'action'" :key="header.value" :class="{'description-header': header.value === 'description'}" class="px-2 single-line">
                                    <span> {{ getItemData(item, header.value) }}</span>
                                </td>
                                <td v-else-if="header.value === 'action'" :key="header.value" class=" px-0 single-line border-right border-left action-data">


                                    <v-tooltip bottom content-class="tooltip-bottom">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn v-bind="attrs" v-on="on" icon @click="editLocation(item)" :disabled="!item.status" small>
                                                <v-icon>mdi-pencil-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Edit</span>
                                    </v-tooltip>

                                    <v-tooltip bottom content-class="tooltip-bottom" v-if="item.status">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn v-bind="attrs" v-on="on" icon @click="enableDisable(item._id, 'disable')" small>
                                                <v-icon color="success">mdi-check-circle</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Enable</span>
                                    </v-tooltip>

                                    <v-tooltip bottom content-class="tooltip-bottom" v-else>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn v-bind="attrs" v-on="on" icon @click="enableDisable(item._id, 'enable')" small>
                                                <v-icon color="error">mdi-close-circle</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Disable</span>
                                    </v-tooltip>

                                </td>
                            </template>
                        </tr>
                    </template>
                </lb-datatableaction>

                <Pagination :list="filteredItemsfromsearch" :itemsperpage="itemsPerPage" @paginated-data="setPaginatedData" />
            </v-card-text>
        </v-card>


      <lb-drawer v-model="locationdialog" :heading="locationdata._id ? 'Edit Location' : 'New Location'" width="600"
        :loading="locationdialogloading">
        <template v-slot:body>
          <div>
            <v-row>
              <v-col cols="12" class="my-0 py-1">
                <p class="pa-0 ma-0  text-subtitle-1 mb-1">{{$store.state.LocationHeader || 'Location'}} Name*</p>
                <lb-string v-model="locationdata.name" label="" :error="locationdataerror.name"  placeholder="Enter name"/>
              </v-col>
  
              <v-col cols="12" class="my-0 py-1">
                <p class="pa-0 ma-0  text-subtitle-1 mb-1">{{$store.state.LocationHeader || 'Location'}} Description*</p>
                <lb-textBox requriedrows="3" v-model="locationdata.description" label="" placeholder="Enter description"
                  :error="locationdataerror.description" />
              </v-col>
              <v-col cols="6" class="my-0 py-1">
                <p class="pa-0 ma-0  text-subtitle-1 mb-1">{{$store.state.LocationHeader || 'Location'}} Country*</p>
                <!-- <lb-dropdown v-model="locationdata.country" :items="countryList" label=""
                  :error="locationdataerror.country" itemtext="name" itemvalue="value"
                   /> -->
                   <lb-tagsInput :multiple="false" :multiplechips="false" v-model="locationdata.country"
                :hidedetails="false" :error="locationdataerror.country" :items="countryList" itemtext="name" itemvalue="value" appendiconinner="mdi-chevron-down"/>
              </v-col>
              <v-col cols="6" class="my-0 py-1">
                <p class="pa-0 ma-0  text-subtitle-1 mb-1">{{$store.state.LocationHeader || 'Location'}} Type*</p>
                <lb-dropdown v-model="locationdata.type" :items="locationtypeItem" label=""
                  :error="locationdataerror.type" itemtext="name" itemvalue="value"
                  @change="locationNew(locationdata.type)" />
              </v-col>
              <v-col v-if="locationtypedialog" cols="6" class="my-0 py-1">
                <p class="pa-0 ma-0  text-subtitle-1 mb-1">Add New {{$store.state.LocationHeader || 'Location'}} Type</p>
                <lb-string v-model="locationdata.newtype" label="" placeholder="Enter Type..." :error="locationdataerror.newtype" />
              </v-col>
            </v-row>
          </div>
        </template>
        <template v-slot:actions>
          <v-spacer></v-spacer>
          <v-btn small color="primary">
            <span v-if="locationdata._id" @click="updateLocation(locationdata)">Update</span>
            <span v-else @click="addLocation()">Add</span>
          </v-btn>
        </template>
      </lb-drawer>
  
     
    </div>
</template>

<script>
    import Pagination from "../../../../views/common/Pagination.vue";
      export default {
        data() {
          return {
            right: "financialclose_location_master",
            locationdialog: false,
            locationdialogloading: false,
            locationdata: {},
            locationdataerror: {},
            loading: false,
            locationtypeItem: [],
            formatedData: [],
            formatedDataCopy:[],
            locationtypedialog: false,
            headers: [
              {
                value: "name",
                text: "Name",
                visible: true,
                filterable:true,
                sortable: true,
              },
              {
                value: "description",
                text: "Description",
                visible: true,
                filterable:true,
                sortable: true,
              },
              {
                value: "type",
                text: "Type",
                visible: true,
                filterable:true,
                sortable: true,
              },
              {
                value: "country",
                text: "Country",
                visible: true,
                filterable:true,
                sortable: true,
              },
              {
                value: "scource",
                text: "Source",
                visible: true,
                filterable:true,
                sortable: true,
              },
              {
                value: "action",
                text: "Action",
                sortable: false,
                datatype: "action",
                lock: true,
                visible: true,
                alignment: "text-center",
              },
            ],
            listdata: [],
            countryList:[],
            editHeader:false,
            newHeader:'Location',
            searchcolumn:"",
            searchTerm:"",
            search:"",
            itemsPerPage:13,
            paginatedFilteredItems:[]
          };
        },
        created() {
          this.refreshData();
        },
        components: {
          Pagination
        },
        computed:{
            filteredHeaders() {
              const searchTerm = this.searchcolumn.toLowerCase();
              return this.headers.filter(header =>
                header.value.toLowerCase()?.includes(searchTerm)
              );
            },
            filteredItemsfromsearch() {
                let filteredData = this.formatedData.filter(item =>
                    item.description.toLowerCase().includes(this.search.toLowerCase())
                );
                return filteredData;
            },
        },
        methods: {
          setPaginatedData(paginatedData) {
            this.paginatedFilteredItems = paginatedData; 
          },
          refreshData(){
            this.getData();
          },
          getItemData(item, key) {
              return item[key];
          },
          formateData() {
            let ObjectTemp = {};
            let ArrayTemp = [];
            console.log(this.listdata, "FOR");
            this.listdata.forEach((item, index) => {
              ObjectTemp = {
                __key: index,
                __formatedkey: index,
                _id: item._id,
                indexno: index + 1,
                name: item.name,
                description: item.description,
                country: item.country,
                type: item.type,
                created_at: item.created_at,
                updated_at: item.updated_at,
                status: item.status,
              };
              ArrayTemp.push(ObjectTemp);
            });
            this.formatedData = ArrayTemp;
            this.formatedDataCopy = ArrayTemp;
            console.log(this.formatedDataCopy,"formatedDataCopy");
            
            
          },
          getData() {
            this.loading = true;
            this.axios
              .post("/v2/financialclose/location/get")
              .then((ele) => {
                if (ele.data.status === "success") {
                  this.listdata = ele.data.data;
                  console.log(this.listdata, "listdata");
                  this.formateData();
                  this.axios
                    .post("/v2/financialclose/location/formselects")
                    .then((ele) => {
                      let locationtypelist = ele.data.data;
                      this.locationtypeItem = locationtypelist[0].type;
                      this.countryList = locationtypelist[0].countryList;
                      this.countryList = this.countryList.map(item => item.name);
                      
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }
              })
              .catch((err) => {
                console.log(err);
              })
              .finally(() => {
                this.loading = false;
              });
          },
          addLocation() {
            this.loading = true;
            this.axios
              .post("/v2/financialclose/location/add", { data: this.locationdata })
              .then((ele) => {
                if (ele.data.status === "success") {
                  this.locationdialog = false;
                  this.$store.commit("sbSuccess", "added successfully");
                  this.getData();
                } else {
                  if (ele.data.data.length > 0) {
                    if (ele.data.data[0].index0)
                      this.locationdataerror = ele.data.data[0].index0;
                  } else {
                    this.$store.commit("sbError", ele.data.message || "Location not added");
                    throw new Error(ele.data.message);
                  }
                }
              })
              .catch((err) => {
                console.log(err);
              })
              .finally(() => {
                this.loading = false;
              });
          },
          editLocation(item) {
            this.locationdata = {...item};
            this.locationdata.type = item.type;
            let typeObj = {
              name: this.locationdata.type,
              value: this.locationdata.type,
            };
            this.locationtypeItem.push(typeObj);
      
            this.locationdialog = true;
            this.locationdataerror = {};
            console.log(item);
          },
          updateLocation(item) {
            console.log(item,"DD");
            this.loading = true;
            this.axios
              .post("v2/financialclose/location/edit/"+ item._id, {  data: item })
              .then((ele) => {
                if (ele.data.status === "success") {
                  this.locationdialog = false;
                  this.$store.commit("sbSuccess", "update successfully");
                  this.getData();
                } else {
                 if (ele.data.data.length > 0) {
                    if (ele.data.data[0].index0)
                      this.locationdataerror = ele.data.data[0].index0;
                  } else throw new Error(ele.data.message);
                }
              })
              .catch((err) => {
                console.log(err);
              })
              .finally(() => {
                this.loading = false;
              });
          },
          locationNew(name) {
            if (name === "Other") {
              this.locationtypedialog = true;
            }
            else{
                this.locationtypedialog=false;
            }
          },
          locationtypeAdd(type) {
            let typeObj = { name: type, value: type };
            this.locationtypeItem.push(typeObj);
            this.locationdata.type = type;
            this.locationtypedialog = false;
          },
          enableDisable(id, action) {
            this.loading = true;
            let url = "/v2/financialclose/location/disable/";
            if (action === "enable") {
              url = "/v2/financialclose/location/enable/";
            }
            this.axios
              .post(url + id, {})
              .then((dt) => {
                if (dt.data.status === "success") {
                  if (action === "enable") {
                    this.getData();
                    this.$store.commit("sbSuccess", "Item enabled");
                  } else {
                    this.getData();
                    this.$store.commit("sbSuccess", "Item disabled");
                  }
                } else throw new Error(dt.data.message || "Error performing action");
              })
              .catch((err) => {
                this.$store.commit("sbError", err.message || err || "Unknown error!");
                console.log(err);
              })
              .finally(() => {
                this.loading = false;
              });
          },
          addTableHeader(item) {
            this.headers.find((x) => x === item ? x.visible = !x.visible : "")
          },
    
          UpdateHeader(){
            if( this.newHeader)
            {
              this.axios.post("v2/client/edit",
                {data:{'location': this.newHeader, userlist:[] },
                  id: this.$route.query.cid || "",
                }).then(dt => {
                  if(dt.data.status=='success')
                  {
                    this.$store.commit("sbSuccess", "Header Updated Successfully")
                    this.$store.commit('setLocationHeader',dt.data.data[0].location)
                  }
                  else
                  {
                    this.$store.commit("sbError", dt.data.message || "Unknown error!");
                  }
                  this.newHeader = this.$store.state.LocationHeader
                });
                this.getLocationHeader()
    
                this.editHeader=false
            }
            else
            {
              this.$store.commit("sbError", "Cannot keep the header empty")
            }
          },
        getLocationHeader(){
          this.axios.post("v2/client/getlocation").then(dt => {
            this.$store.commit('setLocationHeader',dt.data.data[0].location);
            this.newHeader= dt.data.data[0].location||this.$store.state.LocationHeader ||'Location'
            console.log("werftghj",this.$store.state.LocationHeader );
            
          })
        },
        filterPart(item,header){
            console.log(item,header);
            this.formatedData = this.$nova.filterByDynamicCriteria(this.formatedDataCopy, item);  
          },
        clearPart(item){
            console.log(item,"item");
            this.formatedData = this.formatedDataCopy;
          }
    
        },
      };
</script>

<style>
</style>